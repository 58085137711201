import { PlanetSVG } from "components/ui/SVG/Planet";
import { DarkModeBtn } from "./style";

interface IProps {
    onClick: () => void;
    transitioning: boolean;
}

export const ToggleThemeButton: React.FC<IProps> = ({
    onClick,
    transitioning
}) => {
    return (
        <DarkModeBtn
            transitioning={transitioning}
            onClick={onClick}
            aria-label="Toggle theme mode"
        >
            <PlanetSVG transitioning={transitioning} />
        </DarkModeBtn>
    );
};
