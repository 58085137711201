import { keyframes } from "styled-components";

export const spinCenter = keyframes`
    to {
        transform: translate(-50%,-50%) rotate(1turn);
    }
`;

export const scaleYToTopFullScreen = keyframes`
    to {
        transform: scaleY(1);
    }
`;

export const heightToTop0 = keyframes`
    to {
        height: 0;
    }
`;

export const heightUp = keyframes`
    from {
        height: 0;
    }
    to {
        height: 70px;
    }
`;

export const fillWidth = keyframes`
    to {
        width: 100%;
    }
`;

export const rotate = keyframes`
    100% {
        transform: rotate(-1turn);
    }
`;
