import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
    theme: "var(--color-scheme)",
    palette: {
        bgColor: "var(--bg-color-primary)",
        bgDarkBlack: "var(--bg-dark-black)",
        bgPrimaryLight: "var(--bg-primary-light)",
        common: {
            bodyText: `var(--body-text)`,
            black: `var(--black)`,
            borderColor: "var(--border-color)"
        },
        gradient: {
            main: "var(--main-gradient)",
            services: "var(--services-gradient)"
        },
        primary: {
            main: "var(--primary-color-bg)",
            contrastText: "white"
        },
        secondary: {
            main: "var(--secondary-color-bg)",
            contrastText: "var(--secondary-color-text)"
        },
        error: {
            main: "var(--error-color)",
            contrastText: "white"
        },
        success: {
            main: "var(--success-color)",
            contrastText: "white"
        }
    }
};
