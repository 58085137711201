import Link from "next/link";
import { createRef, useEffect, useState } from "react";
import { Container } from "../Container";
import {
    Nav,
    NavBarWrapper,
    NavItem,
    NavLink,
    NavList,
    NavLogo,
    NavMenuToggler
} from "./style";
import { routes } from "./links";
import { SkipNavigation } from "../SkipNavigation";
import Logo from "assets/logo.svg";

export const NavBar: React.FC = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const navbar = createRef<HTMLDivElement>();
    useEffect(() => {
        function setBackgroundOnScroll() {
            if (!navbar.current) {
                return;
            }
            const sticky = navbar.current?.offsetHeight;
            if (window.pageYOffset > sticky) {
                navbar.current.classList.add("scrolled");
            } else {
                navbar.current.classList.remove("scrolled");
            }
        }

        window.addEventListener("scroll", setBackgroundOnScroll);
        return () => {
            if (showMenu) {
                setShowMenu(false);
            }
            window.removeEventListener("scroll", setBackgroundOnScroll);
        };
    }, [navbar, showMenu]);

    return (
        <NavBarWrapper id="top-bar" ref={navbar}>
            <SkipNavigation />
            <Container>
                <Link href="/" aria-label="Home">
                    <NavLogo>
                        <Logo />
                    </NavLogo>
                </Link>
                <Nav>
                    <NavList showMenu={showMenu}>
                        {routes.map((route, index) => (
                            <NavItem
                                total={routes.length}
                                index={index}
                                key={index}
                            >
                                <Link
                                    href={route.path}
                                    aria-label={route.name}
                                    prefetch={false}
                                >
                                    <NavLink>{route.name}</NavLink>
                                </Link>
                            </NavItem>
                        ))}
                    </NavList>
                    <NavMenuToggler
                        showMenu={showMenu}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </NavMenuToggler>
                </Nav>
            </Container>
        </NavBarWrapper>
    );
};
