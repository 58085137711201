import { rotate } from "components/ui/animations";
import styled, { css } from "styled-components";

interface IProps {
    transition?: boolean;
}

export const Comet = styled.path.attrs({
    d: "M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z",
    fill: "#fff"
})<IProps>`
    display: none;
    ${props =>
        props.transition &&
        css`
            display: block;
            animation: ${rotate} 1s linear infinite forwards;
        `}
`;
