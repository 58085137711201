import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        padding:0;
        margin:0;
        color:${props => props.theme.palette.common.black};
        background:${props => props.theme.palette.bgColor}
    }

    html {
        box-sizing: border-box;
        color-scheme: ${props => props.theme.theme};
        scroll-behavior: smooth;
    }

    *, *:before, *:after {
        box-sizing: inherit;
        outline: none;
        font-family: inherit;
    }

    h1, h2, h3, h4, h5, h6, p, ol, ul {
        margin: 0;
    }

    ol, ul {
        list-style: none;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    button {
        cursor: pointer;
    }
    #__next {
        display:flex;
        flex-direction:column;
        height:100vh;
        justify-content:space-between;
    }

     #nprogress .bar {
        background: ${({ theme }) => theme.palette.primary.main};
        height:5px;
    }
    .body-section {
        padding: 100px 0;
    }
    :not(.skip-navigation):focus-visible {
        outline: 2px solid ${props => props.theme.palette.primary.main}
    }

    @supports not selector(:focus-visible) {
        outline:none;
    }
`;
