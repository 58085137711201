import { ThemeMode } from "hooks/useDarkMode";

interface ColorSingle {
    [ThemeMode.LIGHT]: string;
    [ThemeMode.DARK]: string;
}

export interface IColors {
    [x: string]: ColorSingle;
}

export const COLORS: IColors = {
    "--body-text": {
        light: "#546681",
        dark: "white"
    },
    "--bg-color-primary": {
        light: "white",
        dark: "#2d3037"
    },
    "--bg-dark-black": {
        light: "white",
        dark: "#27292d"
    },
    "--black": {
        light: "#203b54",
        dark: "white"
    },
    "--border-color": {
        light: "#e6e6e6",
        dark: "#525252"
    },
    "--main-gradient": {
        light: "linear-gradient(to top, #dfe7ef -15%, transparent 27%)",
        dark: "linear-gradient(179deg, #393c42, #27292d)"
    },
    "--services-gradient": {
        light: "white",
        dark: "linear-gradient(179deg, #393c42, #2d3037)"
    },
    "--bg-primary-light": {
        light: "#f8f9fa",
        dark: "var(--bg-dark-black)"
    },
    "--primary-color-bg": {
        light: "#1065ed",
        dark: "#FF6A00"
    },
    "--secondary-color-bg": {
        light: "rgb(236, 242, 250)",
        dark: "rgb(236, 242, 250)"
    },
    "--secondary-color-text": {
        light: "#203b54",
        dark: "#203b54"
    },
    "--error-color": {
        light: "#ff5558",
        dark: "#ff5558"
    },
    "--success-color": {
        light: "#09a509",
        dark: "#09a509"
    },
    "--color-scheme": {
        light: "light",
        dark: "dark"
    },
    "--planet-svg-primary": {
        light: "#c6cbef",
        dark: "#fec054"
    },
    "--planet-svg-secondary": {
        light: "#949bdd",
        dark: "#ce9a3f"
    }
};
