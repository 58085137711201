import { Comet } from "./style";

interface IProps {
    transitioning: boolean;
}

export const PlanetSVG: React.FC<IProps> = ({ transitioning }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 160 160"
            width="160"
            height="160"
        >
            <defs>
                <filter
                    id="Path_76"
                    x="21"
                    y="24"
                    width="118"
                    height="118"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodColor="#c6cbef" floodOpacity="0.278" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="Path_77"
                    x="20.999"
                    y="24.002"
                    width="103.357"
                    height="103.356"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodColor="#c6cbef" floodOpacity="0.278" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>

            <g id="moon" transform="translate(33 48)">
                <g id="first_half" transform="translate(-5 -20)">
                    <g
                        transform="matrix(1, 0, 0, 1, -28, -28)"
                        filter="url(#Path_76)"
                    >
                        <path
                            id="Path_76-2"
                            data-name="Path 76"
                            d="M50,0A50,50,0,1,1,0,50,50,50,0,0,1,50,0Z"
                            transform="translate(30 30)"
                            fill="var(--planet-svg-primary)"
                        />
                    </g>
                </g>
                <g transform=" matrix(0.866, -0.5, 0.25, 0.433, 43, 37)">
                    <Comet transition={transitioning} />
                </g>
                <g id="second_half" transform="translate(-5 -20)">
                    <g
                        transform="matrix(1, 0, 0, 1, -28, -28)"
                        filter="url(#Path_77)"
                    >
                        <path
                            id="Path_77-2"
                            data-name="Path 77"
                            d="M3844-11974.494a50,50,0,0,1,50-50,49.842,49.842,0,0,1,35.354,14.644l-70.711,70.713A49.848,49.848,0,0,1,3844-11974.494Z"
                            transform="translate(-3814 12054.5)"
                            fill="var(--planet-svg-primary)"
                        />
                    </g>
                    <path
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
                        transform="translate(27 15.333)"
                        fill="var(--planet-svg-secondary)"
                    />
                    <path
                        id="Ellipse_14"
                        data-name="Ellipse 14"
                        d="M8.333,0A8.333,8.333,0,1,1,0,8.333,8.333,8.333,0,0,1,8.333,0Z"
                        transform="translate(13.667 37)"
                        fill="var(--planet-svg-secondary)"
                    />
                    <path
                        id="Ellipse_15"
                        data-name="Ellipse 15"
                        d="M6.667,0A6.667,6.667,0,1,1,0,6.667,6.667,6.667,0,0,1,6.667,0Z"
                        transform="translate(17 60.333)"
                        fill="var(--planet-svg-secondary)"
                    />
                    <path
                        id="Path_75"
                        data-name="Path 75"
                        d="M97.993,47.993A49.98,49.98,0,0,1,8.01,77.977,49.972,49.972,0,0,0,77.977,8.01,49.937,49.937,0,0,1,97.993,47.993Z"
                        transform="translate(4.007 4.007)"
                        opacity="0.1"
                    />
                </g>
            </g>
        </svg>
    );
};
