import styled from "styled-components";

export const FooterBottom = styled.div`
    display: flex;
    padding: 1.25rem 0 3.125rem 0;
    font-size: 0.9375rem;
    justify-content: center;
    text-align: center;
    color: ${props => props.theme.palette.common.bodyText};
`;

const FooterSM = styled.a`
    font-size: 1.5rem;
    display: inline-block;
    margin-right: 0.9375rem;
    transition: 0.3s ease;
    &:hover {
        transform: translateY(-10px);
    }
`;

export const Facebook = styled(FooterSM)`
    color: #1065ed !important;
`;
export const Instagram = styled(FooterSM)`
    color: #e52364 !important;
`;
export const Twitter = styled(FooterSM)`
    color: #03a6f1 !important;
`;
export const Linkedin = styled(FooterSM)`
    color: #0a66c2 !important;
`;

export const FooterParagraph = styled.p`
    max-width: 270px;
    line-height: 1.5625rem;
    font-size: 0.875rem !important;
    display: flex;
    align-items: center;
    font-style: normal;
    margin-bottom: 5px;
    transition: 0.3s ease;
    color: ${props => props.theme.palette.common.bodyText};
    svg {
        margin-right: 10px;
        path {
            stroke: ${props => props.theme.palette.common.bodyText};
        }
    }
    a {
        color: ${props => props.theme.palette.common.bodyText};
    }
`;

export const FooterHeading = styled.h3`
    font-weight: 600;
    font-size: 1rem;
    color: ${props => props.theme.palette.common.black};
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
`;

interface IFooterLink {
    hover?: boolean;
}

export const FooterLinks = styled.div<IFooterLink>`
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    &:last-child {
        ${FooterParagraph} {
            margin-bottom: 1.25rem;
        }
    }
    ${props =>
        props.hover &&
        `
        ${FooterParagraph}:hover {
            color: ${props.theme.palette.primary.main};
            transform:translateX(5px);
        }
    `}
`;

export const FooterTop = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 40px;
`;

export const FooterWrapper = styled.footer`
    margin-top: 6.25rem;
    background: ${props => props.theme.palette.gradient.main};
    border-top: 1px solid ${props => props.theme.palette.common.borderColor};
    padding-top: 3.125rem;
    .container {
        hr {
            margin: 2.5rem 0 0.625rem 0;
            background: ${props => props.theme.palette.common.bodyText};
            opacity: 0.3;
        }
    }
    @media (min-width: 1024px) {
        margin-top: 12.5rem;
    }
`;

export const FooterLogo = styled.div`
    object-fit: contain;
`;
