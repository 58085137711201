import { ThemeMode } from "hooks/useDarkMode";
import styled from "styled-components";

export const SkipNavigationWrapper = styled.a`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props =>
        props.theme.theme === ThemeMode.DARK ? "#2d3037" : "white"};
    color: ${props => props.theme.palette.common.black};
    opacity: 0;
    pointer-events: none;
    &:focus {
        opacity: 1;
        pointer-events: all;
        span {
            outline: 5px solid ${props => props.theme.palette.primary.main};
        }
    }
`;
