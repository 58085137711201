export const routes = [
    {
        path: "/",
        name: "Home"
    },
    {
        path: "/#services",
        name: "Services & Technologies"
    },
    // {
    //     path: "/blog",
    //     name: "Blog"
    // },
    {
        path: "/contact",
        name: "Contact"
    }
];
