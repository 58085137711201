import { SkipNavigationWrapper } from "./style";

export const SkipNavigation: React.FC = () => {
    return (
        <SkipNavigationWrapper
            tabIndex={0}
            className="skip-navigation"
            href="#main-content"
        >
            <span>Skip navigation</span>
        </SkipNavigationWrapper>
    );
};
