import styled from "styled-components";

interface IShowMenuProps {
    showMenu: boolean;
}

export const NavMenuToggler = styled.div<IShowMenuProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 25px;
    span {
        display: block;
        width: 100%;
        height: 4px;
        background: ${props => props.theme.palette.common.black};
        transform-origin: right center;
        transition: 0.3s ease;
    }
    ${props =>
        props.showMenu &&
        `
        span:first-child{
            transform: rotate(-45deg);
        }
        span {
            &:nth-child(2) {
                transform-origin: center;
                opacity:0;
                transform:scale(0)
            }
        }
        span:last-child {
            transform: rotate(45deg);
        }
    `}
`;

export const NavLink = styled.span`
    display: inline-flex;
    text-transform: uppercase;
    font-size: 0.9375rem;
    font-weight: 600;
    color: ${props => props.theme.palette.common.bodyText};
    transition: color 0.3s ease;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    &:hover {
        color: ${props => props.theme.palette.primary.main};
    }
`;

interface INavItemProps {
    index: number;
    total: number;
}

export const NavItem = styled.li<INavItemProps>`
    counter-increment: navbar-list;
    display: flex;
    align-items: center;
    height: calc(100% / ${props => props.total});
    transition: transform 0.3s ease;
    transition-delay: ${props => (props.index + 1) / 10}s;
    transform: translateX(100%);
    justify-content: center;
    &:not(:last-child) {
        border-bottom: 1px solid
            ${props => props.theme.palette.common.borderColor};
    }
`;

export const NavList = styled.ul<IShowMenuProps>`
    text-align: center;
    display: flex;
    position: fixed;
    top: 95px;
    left: 0;
    width: 100%;
    height: calc(100% - 95px);
    flex-direction: column;
    pointer-events: none;
    ${props =>
        props.showMenu &&
        `
        ${NavItem} {
                border-top: 1px solid ${props.theme.palette.common.borderColor};
                pointer-events: all;
                transform: translateX(0%);
            }
        `}
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
`;

export const NavLogo = styled.span`
    display: inline-flex;
    justify-items: center;
    align-items: center;
    img {
        object-fit: contain;
    }
`;

export const NavBarWrapper = styled.div`
    height: 95px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: transparent;
    transition: 0.3s ease;
    &.scrolled {
        background: ${props => props.theme.palette.bgDarkBlack};
    }
    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
    @media (min-width: 768px) {
        ${NavList} {
            position: static;
            top: 0;
            flex-direction: row;
            height: 100%;
            pointer-events: all;
            ${NavItem} {
                height: 100%;
                border: none;
                transform: translateX(0%);
                margin: 0 20px;
            }
            ${NavLink} {
                width: auto;
            }
        }
        ${NavMenuToggler} {
            display: none;
        }
    }
    @media (max-width: 768px) {
        border-bottom: 1px solid
            ${props => props.theme.palette.common.borderColor};
        background: ${props => props.theme.palette.bgDarkBlack};
        ${NavItem} {
            background: ${props => props.theme.palette.bgDarkBlack};
        }
    }
`;
