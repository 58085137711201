import { Dispatch, SetStateAction, useEffect } from "react";
import {
    BlueTransition,
    DarkTransition,
    TransitionContainer,
    TransitionWrapper
} from "./style";

interface IProps {
    toggleTransition: Dispatch<SetStateAction<boolean>>;
    toggleTheme: () => void;
}

export const PageTransition: React.FC<IProps> = ({
    toggleTransition,
    toggleTheme
}) => {
    useEffect(() => {
        const transitionTime = setTimeout(() => {
            toggleTransition(false);
        }, 2500);

        const toggleThemeTime = setTimeout(() => {
            toggleTheme();
        }, 1000);

        return () => {
            clearTimeout(transitionTime);
            clearTimeout(toggleThemeTime);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <TransitionWrapper>
            <TransitionContainer>
                <DarkTransition />
                <BlueTransition />
            </TransitionContainer>
        </TransitionWrapper>
    );
};
