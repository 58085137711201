import styled from "styled-components";
import { heightToTop0, scaleYToTopFullScreen } from "../animations";

export const BlueTransition = styled.div`
    width: 100%;
    height: 100%;
    background: #1065ed;
    animation: ${scaleYToTopFullScreen} 1s ease-in-out 0.8s forwards;
    position: absolute;
    bottom: 0;
    transform: scaleY(0);
    transform-origin: bottom;
`;

export const DarkTransition = styled.div`
    width: 100%;
    height: 100%;
    background: #203b54;
    animation: ${scaleYToTopFullScreen} 1s ease-in-out forwards;
    position: absolute;
    bottom: 0;
    transform: scaleY(0);
    transform-origin: bottom;
`;

export const TransitionWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 15;
    animation: ${heightToTop0} 0.7s ease-in-out 1.6s forwards;
`;

export const TransitionContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
