import { Footer } from "../Footer";
import { NavBar } from "../NavBar";
import { MainContent } from "./style";

interface IProps {
    children: React.ReactNode;
}

export const FrontLayout: React.FC<IProps> = ({ children }) => {
    return (
        <>
            <NavBar />
            <MainContent id="main-content">{children}</MainContent>
            <Footer />
        </>
    );
};
