import styled from "styled-components";

interface IDarkModeToggleThemeBtn {
    transitioning: boolean;
}

export const DarkModeBtn = styled.button<IDarkModeToggleThemeBtn>`
    position: fixed;
    background: #455665;
    bottom: 40px;
    right: 40px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    cursor: pointer;
    border: none;
    transition: 0.3s ease;
    z-index: 100;
    padding: 4px !important;
    img {
        width: 100%;
    }
    ${props =>
        props.transitioning && `transform: scale(0.8); pointer-events: none;`}
    &:active {
        transform: scale(0.8);
    }
    svg {
        width: 100%;
        height: 100%;
    }
`;
