import styled from "styled-components";

export const Container = styled.div.attrs({
    className: "container"
})`
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    @media (min-width: 640px) {
        max-width: 600px;
    }
    @media (min-width: 768px) {
        max-width: 820px;
    }

    @media (min-width: 1024px) {
        max-width: 1050px;
    }

    @media (min-width: 1280px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1320px;
    }
`;
