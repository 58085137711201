import { ThemeMode } from "hooks/useDarkMode";
import { IColors } from "components/theme/COLORS";

export const getUserPreferredTheme = () => {
    const preferredTheme =
        typeof window !== undefined && localStorage.getItem("theme");
    const hasPreferredTheme = typeof preferredTheme === "string";
    if (hasPreferredTheme) {
        return preferredTheme;
    }

    const colorScheme = window.matchMedia("(prefers-color-scheme: dark)");
    const hasPreferredColorScheme = typeof colorScheme.matches === "boolean";

    if (hasPreferredColorScheme) {
        return colorScheme.matches ? "dark" : "light";
    }

    return "light";
};

export const setInlineCssVariables = (theme: ThemeMode, colors: IColors) => {
    Object.entries(colors).forEach(([name, color]) => {
        const root = document.documentElement;
        if (!root) {
            return;
        }

        root.style.setProperty(name, color[theme]);
    });
};
