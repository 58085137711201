import { COLORS } from "components/theme/COLORS";
import { setInlineCssVariables } from "helpers/preferredTheme";
import { useState } from "react";

export enum ThemeMode {
    DARK = "dark",
    LIGHT = "light"
}

const getDocumentColorScheme = (): ThemeMode => {
    if (typeof window !== "undefined") {
        const currentTheme =
            document.documentElement.style.getPropertyValue("--color-scheme");
        return currentTheme as ThemeMode;
    }

    return ThemeMode.LIGHT;
};

export function useDarkMode() {
    const [theme, setTheme] = useState<ThemeMode>(getDocumentColorScheme);
    const toggleTheme = () => {
        const newTheme =
            theme === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        setTheme(newTheme);
        setInlineCssVariables(newTheme, COLORS);
        localStorage.setItem("theme", newTheme);
    };

    return {
        theme,
        toggleTheme
    };
}
