import type { AppProps } from "next/app";
import { GlobalStyle } from "components/theme/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "components/theme/theme";
import { FrontLayout } from "components/ui/Layouts/FrontLayout";

import { ToggleThemeButton } from "components/ui/ToggleThemeBtn";
import { useEffect, useState } from "react";
import { PageTransition } from "components/ui/PageTransition";
import { NextSeo } from "next-seo";
import { graphqlClient } from "graphql/client";
import { ApolloProvider } from "@apollo/client";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import Router from "next/router";
import { useDarkMode } from "hooks/useDarkMode";
import Script from "next/script";
import { Inter } from "@next/font/google";

const inter = Inter({ subsets: ["latin"], display: "swap" });

function MyApp({ Component, pageProps }: AppProps) {
    NProgress.configure({ showSpinner: false });
    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
    const { toggleTheme } = useDarkMode();
    const [transition, toggleTransition] = useState<boolean>(false);
    useEffect(() => {
        //eslint-disable-next-line no-console
        console.log(
            "%c Oh! I see what you doing there 👀",
            "background:#D93025; font-size:1.5rem"
        );
    }, []);

    return (
        <>
            <style jsx global>{`
                html {
                    font-family: ${inter.style.fontFamily};
                }
            `}</style>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
            />
            <Script id="g-script" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date())
                    gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
                `}
            </Script>
            <NextSeo title="Full-time freelancer as Web Developer and UI/UX Designer" />
            <ApolloProvider client={graphqlClient}>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <FrontLayout>
                        <Component {...pageProps} />
                    </FrontLayout>
                    <ToggleThemeButton
                        transitioning={transition}
                        onClick={() => toggleTransition(true)}
                    />
                </ThemeProvider>
                {transition && (
                    <PageTransition
                        toggleTheme={toggleTheme}
                        toggleTransition={toggleTransition}
                    />
                )}
            </ApolloProvider>
        </>
    );
}

export default MyApp;
