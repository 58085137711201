import Link from "next/link";
import { Container } from "../Container";
import { routes } from "../NavBar/links";
import {
    Facebook,
    FooterBottom,
    FooterHeading,
    FooterLinks,
    FooterLogo,
    FooterParagraph,
    FooterTop,
    FooterWrapper,
    Instagram,
    Linkedin,
    Twitter
} from "./style";
import {
    TwitterIcon,
    LocationIcon,
    DribbbleIcon,
    LinkedinIcon,
    FacebookRoundedIcon,
    EmailIcon
} from "../Icons";
import Logo from "assets/logo.svg";

export const Footer: React.FC = () => {
    return (
        <FooterWrapper id="footer">
            <Container>
                <FooterTop>
                    <div>
                        <Link href="/" aria-label="Home">
                            <FooterLogo>
                                <Logo />
                            </FooterLogo>
                        </Link>

                        <FooterHeading>
                            Building enjoyable experiences.
                        </FooterHeading>
                        <FooterParagraph>
                            Web developer and UI/UX Designer with over 3 years
                            of experience ready to take your online business to
                            the next level.
                        </FooterParagraph>
                    </div>
                    <FooterLinks hover={true}>
                        <FooterHeading>Important Links</FooterHeading>
                        {routes.map((route, i) => (
                            <Link
                                key={i}
                                href={route.path}
                                aria-label={route.name}
                            >
                                <FooterParagraph>{route.name}</FooterParagraph>
                            </Link>
                        ))}
                    </FooterLinks>
                    <FooterLinks>
                        <FooterHeading>Reach me</FooterHeading>
                        <FooterParagraph as="address">
                            <LocationIcon />
                            Aourir, Agadir Morocco
                        </FooterParagraph>
                        <FooterParagraph as="address">
                            <EmailIcon />
                            <a href="mailto:benalizakaria.me@gmail.com">
                                benalizakaria.me@gmail.com
                            </a>
                        </FooterParagraph>
                        <div>
                            <Facebook
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/facebook"
                                aria-label="Facebook"
                            >
                                <FacebookRoundedIcon />
                            </Facebook>
                            <Instagram
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/dribbble"
                                aria-label="Dribbble"
                            >
                                <DribbbleIcon />
                            </Instagram>
                            <Twitter
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/twitter"
                                aria-label="Twitter"
                            >
                                <TwitterIcon />
                            </Twitter>
                            <Linkedin
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/linkedin"
                                aria-label="Linkedin"
                            >
                                <LinkedinIcon />
                            </Linkedin>
                        </div>
                    </FooterLinks>
                </FooterTop>
                <hr />
                <FooterBottom>
                    Copyrights © {new Date().getFullYear()}. All rights reserved
                </FooterBottom>
            </Container>
        </FooterWrapper>
    );
};
